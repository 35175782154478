<nav>
    <div class="d-flex align-items-center justify-content-center p-2 nav-header w-100 flex-shrink-0">
        @if (!isNavBarCollapsed) {
            <div class="logo d-flex p-2 me-2 justify-content-center align-items-center">
                <img [src]="logo" alt="Feedonomics" height="28">
            </div>
            <span class="h5 mb-0">Feedonomics</span>
        }
        <button
            type="button"
            [ngClass]="{ 'ms-auto': !isNavBarCollapsed, 'p-0': isNavBarCollapsed }"
            class="btn btn-link btn-ghost btn-nav text-secondary"
            (click)="onToggleNavVisibility()">
            <fa-icon [icon]="faArrowLeftToLine" class="no-margin" [transform]="isNavBarCollapsed ? 'rotate-180' : 'rotate-0'"/>
        </button>
    </div>

    @if (isNavBarCollapsed) {
        <fdx-app-vertical-menu-collapsed [navGroups]="navGroups" class="h-100"/>
    } @else {
        <fdx-app-vertical-menu-expanded [navGroups]="navGroups" class="h-100"/>
    }

    <div ngbDropdown
         [placement]="isNavBarCollapsed ? 'right-bottom' : 'top-start'">
        <div
            ngbDropdownToggle
            class="d-flex align-items-center flex-shrink-0 align-self-stretch w-100 footer gap-2 p-2">
            <fdx-user-initials [user]="user"/>
            @if (!isNavBarCollapsed) {
                <div class="vstack user-details justify-content-center">
                <span
                    class="h6 mb-0 acct-details"
                    [ngbTooltip]="accountName"
                    [openDelay]="500"
                    placement="end">
                    {{accountName}}
                </span>
                    @if (databaseName) {
                        <p
                            class="mb-0 text-secondary acct-details"
                            [ngbTooltip]="databaseName"
                            [openDelay]="500"
                            placement="end">
                            {{databaseName}}
                        </p>
                    }
                </div>
            }

        </div>
        <div
            ngbDropdownMenu
            class="rounded-3 ms-2"
            aria-labelledby="profileInfo">
            <div class="d-flex gap-2 p-3 pt-2 border-bottom align-items-center">
                <fdx-user-initials [user]="user"/>
                <div class="vstack justify-content-center">
                    @if (user?.first_name && user?.last_name) {
                        <span class="h6 mb-0">{{user.first_name}} {{user.last_name}}</span>
                    }
                    <p class="mb-0 text-secondary">{{userName}}</p>
                </div>
            </div>
            <a
                ngbDropdownItem
                href="https://store-pw30au63mu.mybigcommerce.com/manage/app/58190"
                target="_blank"
                rel="noopener"
                referrerpolicy="no-referrer">
                <fa-icon
                    [icon]="faArrowUpRightFromSquare"
                    class="me-2 text-secondary"/>
                <span>Go to BigCommerce</span>
            </a>
            <a ngbDropdownItem
            [routerLink]="linkService.logsChangeLink">
                <fa-icon
                    [icon]="faClockRotateLeft"
                    class="me-2 text-secondary"/>
                <span>Change logs</span>
            </a>
            @if ((isPrivacyLevelAtLeastAnalyst || isClientDemoView) && !isImpersonatingUser) {
                <button
                    ngbDropdownItem
                    type="button"
                    (click)="toggleClientDemoView()">
                    <fa-icon
                        [icon]="faArrowRightArrowLeft"/>
                    <span>Return to default view</span>
                </button>
            }
            <a
                ngbDropdownItem
                class="text-danger"
                [routerLink]="linkService.logoutLink" [queryParams]="{'app': 'feedonomics', 'redirect': 'https://login.bigcommerce.com/login'}">
                <fa-icon
                    class="me-2 text-danger"
                    [icon]="faArrowRightFromBracket"/>
                <span>Log out</span>
            </a>
    </div>
    </div>
</nav>
